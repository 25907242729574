import { inject, Injectable, OnDestroy, OnInit } from '@angular/core';

import { SOCIAL_ICON_ENUM } from '@app/enums/social-icon';
import { Account } from '@app/models/authentication/account.model';
import { SocialIcon } from '@app/models/social-icon.model';
import { SOCIAL_ICON_NAME } from '@app/types/social-icon.type';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Subscription } from 'rxjs';
import { LoginObservable } from '@app/observables/login.observable';

@Injectable({
    providedIn: 'root',
})
export class SocialSharedService implements OnDestroy {
    currentAccount: Account | null;
    private readonly data: SocialIcon[];
    listSubscription: Subscription[] = [];
    private readonly loginObservable: LoginObservable = inject(LoginObservable);

    constructor() {
        this.listSubscription = [new Subscription()];

        this.data = [
            {
                name: 'views',
                icon: SOCIAL_ICON_ENUM.views,
                quantity: 0,
                text: true,
                url: '',
                message: '',
                click: false,
            },
            {
                name: 'heart',
                icon: SOCIAL_ICON_ENUM.heartEmpty,
                quantity: 0,
                text: true,
                url: '',
                message: '',
                click: true,
            },
            {
                name: 'whatsapp',
                icon: SOCIAL_ICON_ENUM.whatsapp,
                quantity: 0,
                text: true,
                url: '',
                message: '',
                click: true,
            },
            {
                name: 'facebook',
                icon: SOCIAL_ICON_ENUM.facebook,
                quantity: 0,
                text: true,
                url: '',
                message: '',
                click: true,
            },
            {
                name: 'santovecino',
                icon: SOCIAL_ICON_ENUM.santovecino,
                quantity: 0,
                text: true,
                url: '',
                message: '',
                click: false,
            },
        ];

        this.subscriptionLogin();
    }

    ngOnDestroy(): void {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscriptionLogin(): void {
        this.listSubscription[0] = this.loginObservable.data$.subscribe(
            (res: Account | null) => {
                this.currentAccount = res;
            }
        );
    }

    getDataOriginal(): SocialIcon[] {
        return this.data;
    }

    getDataChange(list: Partial<SocialIcon>[]): SocialIcon[] {
        const listNew: SocialIcon[] = [];

        this.data.forEach((itrO: SocialIcon) => {
            list.forEach((itrN: Partial<SocialIcon>) => {
                if (itrO.name === itrN.name) {
                    listNew.push({
                        ...itrO,
                        ...itrN,
                    });
                }
            });
        });
        return listNew;
    }

    updateData(
        name: SOCIAL_ICON_NAME,
        newData: Partial<Omit<SocialIcon, 'name' | 'icon'>>,
        list: SocialIcon[]
    ): SocialIcon[] {
        const listNew: SocialIcon[] = list.map(item => {
            if (item.name === name) {
                return {
                    ...item,
                    ...newData,
                };
            }

            return item;
        });

        return listNew;
    }

    setData(newData: SocialIcon, list: SocialIcon[]): SocialIcon[] {
        const newList = [...list];
        newList.push(newData);
        return newList;
    }

    checkLikes(likes: any, list: SocialIcon[]): SocialIcon[] {
        return list.map((item: SocialIcon) => {
            if (item.name == 'heart') {
                return {
                    ...item,
                    icon: this.viewLike(likes)
                        ? SOCIAL_ICON_ENUM.heartFill
                        : SOCIAL_ICON_ENUM.heartEmpty,
                };
            }

            return item;
        });
    }

    viewFollower(follower: any, user: any): boolean {
        return follower.filter((itrF: any) => {
            return itrF == user.userId;
        }).length == 0
            ? false
            : true;
    }

    viewLike(likes: any): boolean {
        if (!this.currentAccount) return false;

        if (!likes) return false;

        if (likes.length == 0) return false;

        return (
            likes.peopleLiked.filter((itrData: any) => {
                return itrData == this.currentAccount?._id;
            }).length != 0
        );
    }
}
